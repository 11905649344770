.header-container {
  align-self: center;

  .logo {
    width: 300px;
    height: auto;
    align-self: center;
    text-align: center;
    margin: 0 auto;
  }

  nav {
    align-self: center;

    ul {
      padding: 0;
    }
    ul li {
      display: inline-block;
      padding: 0 1.4em 0 0;
      font-size: 0.9em;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

$link-color: #222450;
